<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card class="d-flex flex-column" style="height: 100%">
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Reject Response</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0"
        style="align-items: center; display: flex; flex-direction: column; flex-grow:1; overflow: auto"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex flex-row"
              style="align-items: baseline"
              v-if="need"
            >
              <div style="font-size: 16px; font-weight: bold;">
                {{ need.title }}
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-row"
              style="align-items: baseline"
              v-if="response"
            >
              <div>
                <editable-avatar
                  :image="
                    response.volunteer && response.volunteer.thumbnail
                      ? response.volunteer.thumbnail
                      : response.volunteer
                      ? response.volunteer.image
                      : null
                  "
                  :editable="false"
                  :size="40"
                  class="mr-5"
                />
                {{
                  response.volunteer.first_name +
                    " " +
                    response.volunteer.last_name
                }}
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-row"
              style="align-items: baseline"
            >
              <label>Shift: </label>
              <div class="ml-5">
                {{ shiftTitle }}
              </div>
            </v-col>
            <v-col cols="12">
              <label class="title-label"
                >Reason for rejection
                <tip-icon>
                  Type any additional notes that why you are rejecting.
                </tip-icon>
              </label>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="reply"
                label="Message"
                rows="2"
                solo
                flat
                dense
                outlined
                auto-grow
                :error-messages="replyMessage"
                @input="onChange"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="loading"
          @click="onSubmitNeedResponse"
        >
          {{ already ? "Edit" : "Submit" }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="cancelling"
          @click="onWithdrawResponse"
          v-if="already"
        >
          Widthdraw
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </v-dialog>
</template>
<script>
import TipIcon from "@/components/common/TipIcon.vue";
import { mapActions, mapState } from "vuex";
import EditableAvatar from "../../../components/EditableAvatar.vue";

export default {
  components: {
    TipIcon,
    EditableAvatar,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    need: Object,
    response: Object,
    shiftTitle: String,
    onCloseDialog: {
      type: Function,
    },
    onRejected: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      snackMessage: null,
      error: null,
      reply: null,
      agency: null,
      replyMessage: null,
      already: false,
      cancelling: false,
    };
  },
  methods: {
    ...mapActions({
      rejectResponse: "response/rejectResponse",
    }),
    onClose() {
      this.reply = null;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    onSubmitNeedResponse() {
      this.error = null;
      this.loading = true;
      this.rejectResponse({ _id: this.response._id, reply: this.reply })
        .then((res) => {
          this.loading = false;
          this.reply = "";
          this.onRejected(res);
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.message;
        });
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
};
</script>
<style scoped>
div :deep(.v-dialog) {
  width: 70%;
  max-width: 900px;
  overflow-y: hidden;
}
.title-label {
  width: 200px;
}

div :deep(.v-dialog) {
  max-height: 80%;
}
.dialog-content {
  flex-grow: 1;
  overflow-y: auto;
}
div :deep(::-webkit-scrollbar) {
  width: 5px;
}

/* Track */
div :deep(::-webkit-scrollbar-track) {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
div :deep(::-webkit-scrollbar-thumb) {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
div :deep(::-webkit-scrollbar-thumb):hover {
  background: #f0a000;
}

.date-picker {
  border-radius: 4px 0 0 4px;
}
.time-picker {
  border-radius: 0 4px 4px 0;
}
.date-picker :deep(.v-picker__title) {
  max-height: 87px;
}
.time-picker :deep(.v-picker__title) {
  max-height: 87px;
}
</style>
